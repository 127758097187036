import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

function SEO({ description, lang, meta, keywords, title, urlPath, image }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          siteUrl
          defaultTitle
          titleTemplate
          description
          twitterHandle
          githubHandle
          instagramHandle
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const metaUrl = urlPath
    ? `${site.siteMetadata.siteUrl}${urlPath}`
    : site.siteMetadata.siteUrl
  const metaImage =
    image ||
    'https://res.cloudinary.com/parinda/image/upload/c_crop,h_457,w_812,x_0,y_0/v1599790526/profile_pic_njbyrm.jpg'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.defaultTitle}
      titleTemplate={`%s ${site.siteMetadata.titleTemplate}`}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          itemprop: `title`,
          content: title,
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterHandle,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  itemprop: 'image',
                  content: metaImage,
                },
                {
                  property: 'og:image',
                  content: metaImage,
                },
                {
                  name: 'twitter:image',
                  content: metaImage,
                },
              ]
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  image: PropTypes.string,
  urlPath: PropTypes.string,
}

export default SEO
